import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { loadStripe } from '@stripe/stripe-js'

export const JoinPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="pt-6 pt-md-11 bg-dark pb-md-10">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">
              <h6 className="text-uppercase text-warning mb-5">
                Work made better
              </h6>

              <h2 className="display-3 mb-4">The community for you.</h2>

              <p className="font-size-lg">
                Whether you are working remotely for a company or building your
                own brand and business, Reno Collective will help you take your
                work to the next level, all while making sure you maintain the
                essential work/life balance.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-10 pt-md-12">
        <div class="container-lg">
          <div class="row align-items-center no-gutters">
            <div class="col-md-7">
              <div class="position-relative">
                <div class="position-absolute bottom-left text-warning ml-n10 mb-n10">
                  <svg
                    width="185"
                    height="186"
                    viewBox="0 0 185 186"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2" r="2" fill="currentColor" />
                    <circle cx="22" cy="2" r="2" fill="currentColor" />
                    <circle cx="42" cy="2" r="2" fill="currentColor" />
                    <circle cx="62" cy="2" r="2" fill="currentColor" />
                    <circle cx="82" cy="2" r="2" fill="currentColor" />
                    <circle cx="102" cy="2" r="2" fill="currentColor" />
                    <circle cx="122" cy="2" r="2" fill="currentColor" />
                    <circle cx="142" cy="2" r="2" fill="currentColor" />
                    <circle cx="162" cy="2" r="2" fill="currentColor" />
                    <circle cx="182" cy="2" r="2" fill="currentColor" />
                    <circle cx="2" cy="22" r="2" fill="currentColor" />
                    <circle cx="22" cy="22" r="2" fill="currentColor" />
                    <circle cx="42" cy="22" r="2" fill="currentColor" />
                    <circle cx="62" cy="22" r="2" fill="currentColor" />
                    <circle cx="82" cy="22" r="2" fill="currentColor" />
                    <circle cx="102" cy="22" r="2" fill="currentColor" />
                    <circle cx="122" cy="22" r="2" fill="currentColor" />
                    <circle cx="142" cy="22" r="2" fill="currentColor" />
                    <circle cx="162" cy="22" r="2" fill="currentColor" />
                    <circle cx="182" cy="22" r="2" fill="currentColor" />
                    <circle cx="2" cy="42" r="2" fill="currentColor" />
                    <circle cx="22" cy="42" r="2" fill="currentColor" />
                    <circle cx="42" cy="42" r="2" fill="currentColor" />
                    <circle cx="62" cy="42" r="2" fill="currentColor" />
                    <circle cx="82" cy="42" r="2" fill="currentColor" />
                    <circle cx="102" cy="42" r="2" fill="currentColor" />
                    <circle cx="122" cy="42" r="2" fill="currentColor" />
                    <circle cx="142" cy="42" r="2" fill="currentColor" />
                    <circle cx="162" cy="42" r="2" fill="currentColor" />
                    <circle cx="182" cy="42" r="2" fill="currentColor" />
                    <circle cx="2" cy="62" r="2" fill="currentColor" />
                    <circle cx="22" cy="62" r="2" fill="currentColor" />
                    <circle cx="42" cy="62" r="2" fill="currentColor" />
                    <circle cx="62" cy="62" r="2" fill="currentColor" />
                    <circle cx="82" cy="62" r="2" fill="currentColor" />
                    <circle cx="102" cy="62" r="2" fill="currentColor" />
                    <circle cx="122" cy="62" r="2" fill="currentColor" />
                    <circle cx="142" cy="62" r="2" fill="currentColor" />
                    <circle cx="162" cy="62" r="2" fill="currentColor" />
                    <circle cx="182" cy="62" r="2" fill="currentColor" />
                    <circle cx="2" cy="82" r="2" fill="currentColor" />
                    <circle cx="22" cy="82" r="2" fill="currentColor" />
                    <circle cx="42" cy="82" r="2" fill="currentColor" />
                    <circle cx="62" cy="82" r="2" fill="currentColor" />
                    <circle cx="82" cy="82" r="2" fill="currentColor" />
                    <circle cx="102" cy="82" r="2" fill="currentColor" />
                    <circle cx="122" cy="82" r="2" fill="currentColor" />
                    <circle cx="142" cy="82" r="2" fill="currentColor" />
                    <circle cx="162" cy="82" r="2" fill="currentColor" />
                    <circle cx="182" cy="82" r="2" fill="currentColor" />
                    <circle cx="2" cy="102" r="2" fill="currentColor" />
                    <circle cx="22" cy="102" r="2" fill="currentColor" />
                    <circle cx="42" cy="102" r="2" fill="currentColor" />
                    <circle cx="62" cy="102" r="2" fill="currentColor" />
                    <circle cx="82" cy="102" r="2" fill="currentColor" />
                    <circle cx="102" cy="102" r="2" fill="currentColor" />
                    <circle cx="122" cy="102" r="2" fill="currentColor" />
                    <circle cx="142" cy="102" r="2" fill="currentColor" />
                    <circle cx="162" cy="102" r="2" fill="currentColor" />
                    <circle cx="182" cy="102" r="2" fill="currentColor" />
                    <circle cx="2" cy="122" r="2" fill="currentColor" />
                    <circle cx="22" cy="122" r="2" fill="currentColor" />
                    <circle cx="42" cy="122" r="2" fill="currentColor" />
                    <circle cx="62" cy="122" r="2" fill="currentColor" />
                    <circle cx="82" cy="122" r="2" fill="currentColor" />
                    <circle cx="102" cy="122" r="2" fill="currentColor" />
                    <circle cx="122" cy="122" r="2" fill="currentColor" />
                    <circle cx="142" cy="122" r="2" fill="currentColor" />
                    <circle cx="162" cy="122" r="2" fill="currentColor" />
                    <circle cx="182" cy="122" r="2" fill="currentColor" />
                    <circle cx="2" cy="142" r="2" fill="currentColor" />
                    <circle cx="22" cy="142" r="2" fill="currentColor" />
                    <circle cx="42" cy="142" r="2" fill="currentColor" />
                    <circle cx="62" cy="142" r="2" fill="currentColor" />
                    <circle cx="82" cy="142" r="2" fill="currentColor" />
                    <circle cx="102" cy="142" r="2" fill="currentColor" />
                    <circle cx="122" cy="142" r="2" fill="currentColor" />
                    <circle cx="142" cy="142" r="2" fill="currentColor" />
                    <circle cx="162" cy="142" r="2" fill="currentColor" />
                    <circle cx="182" cy="142" r="2" fill="currentColor" />
                    <circle cx="2" cy="162" r="2" fill="currentColor" />
                    <circle cx="22" cy="162" r="2" fill="currentColor" />
                    <circle cx="42" cy="162" r="2" fill="currentColor" />
                    <circle cx="62" cy="162" r="2" fill="currentColor" />
                    <circle cx="82" cy="162" r="2" fill="currentColor" />
                    <circle cx="102" cy="162" r="2" fill="currentColor" />
                    <circle cx="122" cy="162" r="2" fill="currentColor" />
                    <circle cx="142" cy="162" r="2" fill="currentColor" />
                    <circle cx="162" cy="162" r="2" fill="currentColor" />
                    <circle cx="182" cy="162" r="2" fill="currentColor" />
                    <circle cx="2" cy="182" r="2" fill="currentColor" />
                    <circle cx="22" cy="182" r="2" fill="currentColor" />
                    <circle cx="42" cy="182" r="2" fill="currentColor" />
                    <circle cx="62" cy="182" r="2" fill="currentColor" />
                    <circle cx="82" cy="182" r="2" fill="currentColor" />
                    <circle cx="102" cy="182" r="2" fill="currentColor" />
                    <circle cx="122" cy="182" r="2" fill="currentColor" />
                    <circle cx="142" cy="182" r="2" fill="currentColor" />
                    <circle cx="162" cy="182" r="2" fill="currentColor" />
                    <circle cx="182" cy="182" r="2" fill="currentColor" />
                  </svg>
                </div>

                <div class="card mb-6 mb-md-0">
                  <div class="card-body position-relative bg-white text-center pb-0">
                    <h3 class="display-4 mb-4">Our Coworking Memberships</h3>

                    <p class="text-muted">
                      Our coworking memberships offer access to our physical
                      space and all of the benefits and perks below.
                    </p>
                  </div>
                  <div class="card-body position-relative bg-white">
                    <ul class="list-group list-group-flush mt-n5">
                      <li class="list-group-item d-flex align-items-center">
                        <strong>Cafe 5</strong>

                        <span class="text-right ml-auto">$150/month</span>
                      </li>
                      <li class="list-group-item d-flex align-items-center">
                        <strong>Cafe 24/7</strong>

                        <span class="text-right ml-auto">$250/month</span>
                      </li>
                      <li class="list-group-item d-flex align-items-center">
                        <strong>Resident Desk</strong>

                        <span class="text-right ml-auto">$400/month</span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer position-relative border-top">
                    <div class="row">
                      <div class="col-12 col-lg"></div>
                      <div class="col-12 col-lg-auto">
                        <Link
                          to="/apply"
                          className="btn btn-success"
                          title="apply for membership"
                        >Apply for Membership</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card bg-gray-800">
                <div class="card-body text-center text-white py-md-10 py-xl-12">
                  <h3 class="display-4 mb-4">Team Rooms</h3>

                  <p class="text-white-80 mb-5">
                    Looking for a room for your budding or remote team?
                  </p>

                  <p class="text-white-80 mb-0">
                    Our team rooms are popular and are rarely available but we'd
                    love to show you how you can build your company at Reno
                    Collective.
                  </p>
                </div>
                <div class="card-footer position-relative border-top border-white-5">
                  <div class="row">
                    <div class="col-12 col-xl">
                      <div class="display-4 font-weight-bolder text-center text-xl-left text-white mb-4 mb-xl-0">
                        <span class="font-size-h3 align-middle">$</span>
                        <span>800</span>
                        <span class="font-size-h2 align-middle">/mo</span>
                      </div>
                    </div>
                    <div class="col-12 col-xl-auto">
                      <a
                        class="btn btn-primary btn-block"
                        href="mailto:hello@renocollective.com"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10 pt-md-12">
        <div className="container-lg">
          <div className="row mt-n6">
            <div className="col-12">
              <div class="table-responsive">
                <table
                  class="table table-flush table-features mb-0"
                  id="tableFeatures"
                >
                  <tbody>
                    <tr>
                      <th class="d-flex align-items-center"></th>
                      <th class="text-center">
                        <p class="font-weight-bold mb-0">Cafe 5</p>
                      </th>
                      <th class="text-center">
                        <p class="font-weight-bold mb-0">Cafe 24/7</p>
                      </th>
                      <th class="text-center">
                        <p class="font-weight-bold mb-0">Resident</p>
                      </th>
                    </tr>
                    <tr>
                      <td>Price / month</td>
                      <td class="text-center">
                        <span class="text-primary">$150</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">$250</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">$400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Dedicated Desk</td>
                      <td class="text-center">
                        <span class="text-primary">No</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">No</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Yes</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Access to working space</td>
                      <td class="text-center">
                        <span class="text-primary">5 days / month</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Unlimited</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Unlimited</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Hours & Days of Week</td>
                      <td class="text-center">
                        <span class="text-primary">M-F 8-6</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">24/7</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">24/7</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Incl. meeting room hours</td>
                      <td class="text-center">
                        <span class="text-primary">1h / month</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Unlimited</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Unlimited</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Access to phone rooms</td>
                      <td class="text-center">
                        <span class="text-primary">Included</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Included</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Included</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/cotivation" title="Cotivation">
                          Cotivation Program
                        </Link>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Included!</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Included!</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">Included!</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Membership Perks</td>
                      <td class="text-center">
                        <span class="text-primary">
                          <Link to="/perks" title="Perks">
                            Learn more
                          </Link>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">
                          <Link to="/perks" title="Perks">
                            Learn more
                          </Link>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">
                          <Link to="/perks" title="Perks">
                            Learn more
                          </Link>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link
                          to="/blog/2021-08-27-introducing-the-rc-parent-pass"
                          title="Parent Pass"
                        >
                          Parent Pass
                        </Link>{' '}
                        <span>✨ new! ✨</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">n/a</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">+ $50/mo</span>
                      </td>
                      <td class="text-center">
                        <span class="text-primary">+ $50/mo</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-10 py-md-12">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6">
              <hr class="hr-sm border-warning mb-10 mb-md-12" />

              <h2 class="display-3 text-center mb-4">
                Ready to reclaim that work life{' '}
                <span class="text-underline-primary-light">balance?</span>
              </h2>

              <p class="text-center text-muted mb-9">
                Or WFH life balance, perhaps?
              </p>

              <p class="text-center">
                <Link
                  to="/apply"
                  className="btn btn-primary lift"
                  title="apply for membership"
                >Apply for Membership</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

JoinPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const JoinPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <JoinPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

JoinPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default JoinPage

export const joinPageQuery = graphql`
  query JoinPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
